import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import MenuItem from './FaqMenuItem';
import Group from './FaqGroup';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {Row, Col, Drawer} from 'antd';
import {Heading} from '../../Widgets';
import {ToggleDrawer} from '../../Widgets';
import {Info} from '@styled-icons/material';
const appConfig = require('../../data.json');

// labels structure
// {
//   display: "",
//   name: "",
//   children: [
//     {
//       display: "",
//       name: "",
//     },
//     ...
//   ]
// }

function FaqPage(props) {
  const app = React.useContext(AppContext.Context);
  const [selected, setSelected] = React.useState();
  const [records, setRecords] = React.useState([]);
  const {faqLabels: labels, services} = app.state;
  const [showDrawer, setShowDrawer] = React.useState(false);

  React.useEffect(() => {
    if (labels.length > 0 && labels[0].children.length > 0) {
      setSelected(labels[0].children[0]);
    }
  }, [labels]);

  React.useEffect(() => {
    async function fetchData() {
      if (services.length > 0) {
        app.actions.setLoading(true);
        let params = {
          $and: [
            {label: 'FAQ'},
            selected ? {label: selected.name} : {label: 'none'},
          ],
        };

        setRecords(await app.actions.getBlogs({query: params}));

        app.actions.setLoading(false);
      }
    }

    fetchData();
  }, [app.actions, selected, services]);

  return (
    <ContentWrapper>
      <Row gutter={{sm: 10, md: 10, lg: 30}} style={{marginBottom: 40}}>
        <Col xs={0} sm={10} md={7} lg={5}>
          <Heading size="sm" color={appConfig.colors.main}>
            客服中心
          </Heading>
          {labels.map((label, idx) => (
            <MenuItem
              key={label.name}
              label={label.name}
              items={label.children}
              defaultExpand={true}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </Col>
        <Col xs={24} sm={14} md={17} lg={19}>
          <Heading size="sm">{selected?.display}</Heading>

          <Group key={selected} records={records} />
        </Col>
      </Row>

      <ToggleDrawer onClick={() => setShowDrawer(true)}>
        <Info size={24} color="white" />
      </ToggleDrawer>

      <Drawer
        title="客服中心"
        placement="left"
        closable={false}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}>
        {labels.map((label, idx) => (
          <MenuItem
            key={label.name}
            label={label.name}
            items={label.children}
            defaultExpand={true}
            selected={selected}
            setSelected={(x) => {
              setSelected(x);
              showDrawer(false);
            }}
          />
        ))}
      </Drawer>
    </ContentWrapper>
  );
}

export default FaqPage;
