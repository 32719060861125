import React from 'react';
import styled from 'styled-components';
import Preview from '../../Components/SlatePreview';
const appConfig = require('../../data.json');

export default function FaqGroup(props) {
  const {records} = props;
  const [selectedIdx, setSelectedIdx] = React.useState(0);

  React.useEffect(() => {
    setSelectedIdx(0);
  }, [records]);

  return (
    <Wrapper>
      {records.map((record, idx) => {
        const selected = idx === selectedIdx;
        return (
          <section key={record.id}>
            <header
              className={`${selected ? 'selected' : ''}`}
              onClick={() => setSelectedIdx(idx)}>
              <div style={{flex: 1}}>{record.title}</div>
              <div>{selected ? '-' : '+'}</div>
            </header>

            <div className={`content ${selected ? 'visible' : ''}`}>
              {selected && <Preview content={record.content} />}
            </div>
          </section>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > section {
    & > header {
      padding: 10px;
      background-color: ${appConfig.colors.border};
      color: ${appConfig.colors.text};
      border: 1px solid #eee;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    & > header.selected {
      background-color: ${appConfig.colors.main};
      color: white;
    }

    & > .content {
      padding: 0px 10px;
      transition: 200ms;
      opacity: 0;
      max-height: 0px;
      pointer-events: none;
    }

    & > .content.visible {
      padding: 10px;
      opacity: 1;
      max-height: 2000px;
      pointer-events: auto;
    }
  }
`;
